import dbMgr from "../dbManager";
import { BASE_VIDEO_URL } from "../user";


export const videoPreLoader = (urls, age, onFinish, lang) => {
    const videoSrcs = urls || null;

    const preloadVideo = async (vkey) => {
        // const src = `${BASE_VIDEO_URL}/age${age}/${vkey}.mp4`;
        const src = `${BASE_VIDEO_URL}/${vkey}.mp4`;
        // console.log('videoPreLoader..preloadVideo:', src);
        const res = await fetch(src);
        // console.log('videoPreLoader..preloadVideo res:', res);
        if (res.status === 200) {
            const blob = await res.blob();
            const resp = await dbMgr.storeInDb(vkey, blob);
            return [vkey, URL.createObjectURL(blob)];
        } else return null;
    }

    const preload = () => {
        // console.log('videoPreLoader.preload preloading...');

        // console.log('videoPreLoader.preload videoSrcs:', videoSrcs, dbMgr);
        if (videoSrcs && videoSrcs.length) {
            let numLoaded = 0;

            for (var i = 0; i < videoSrcs.length; i++) {
                const vkey = `${videoSrcs[i]}_${lang}`;
                // console.log('videoPreLoader.preload vkey:', vkey);
                dbMgr.readFromDb(vkey).then((blob) => {
                    // console.log("videoPreLoader.preload preloaded from DB:", vkey/* , blob */);
                    numLoaded++;
                    if (numLoaded >= videoSrcs.length) {
                        // console.log('videoPreLoader.preload finished...');
                        if (onFinish) onFinish(true);
                    }
                }).catch((err) => {
                    // console.log("videoPreLoader.preload err:", err);
                    preloadVideo(vkey).then((arr) => {
                        // console.log("videoPreLoader.preload preloaded from URL:", vkey, arr);
                        numLoaded++;
                        if (numLoaded >= videoSrcs.length) {
                            // console.log('videoPreLoader.preload finished...');
                            if (onFinish) onFinish(true);
                        }
                    });
                });
            }

        }else{
            if (onFinish) onFinish(true);
        }
    }

    return {
        preload
    };
}

