import { Collapse, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMobileOperatingSystem, moveToScrollToElem, userHasSubscription } from '../../utils';

import ModalLoader from '../../components/Course/ModalLoader';

import { loadResponses, saveResponses } from '../../modules/response';

import plan from '../../data/plan';

import showLevelTest from '../../components/Course/LevelTest';
import showLevelUnits from '../../components/Course/LevelUnits';
import BottomMenu from '../../components/Menu';


import { Redirect, RouteComponentProps } from 'react-router';
import SectionHeader from '../../components/Utils/SectionHeader';
import { User } from '../../models/user';
import { loadSubscriptions } from '../../modules/subscription';
import { lockPortrait, unlockScreen } from '../../utils/screen';

import SubscribeButton from '../../components/Subscription/SubscribeButton';
import { recordPageGA } from '../../modules/ga';

import { useHistory } from "react-router-dom";
import { loadUserStrengthen } from '../../modules/tutor';
import { getUserData } from '../../modules/user';

import './index.less';

const innerHeight = window.innerHeight;

interface IProps extends RouteComponentProps<any> {
  user: User;
  results: any;
  lang: string;
  saveUser: (user: User) => void;
  loadResponses: (activeChild: any) => void;
  saveResponses: (uid, ex, result) => void;
  isLoading: boolean;
  saved: boolean;
  error: boolean;
  transactions: any;
  products: any;
  loadSubscriptions: () => void;
  getUserData: () => void;
  strengthen_units: any
  loadUserStrengthen: (activeChild: any) => void;
}

const LIMITLEVEL = 3;
const LIMITUNIT = 0;
const HASSUBSCRIPTION = false;

const Learn = ({
  user,
  results,
  lang,
  saveUser,
  loadResponses,
  saveResponses,
  isLoading,
  saved,
  error,
  transactions,
  products,
  loadSubscriptions,
  getUserData,
  strengthen_units,
  loadUserStrengthen
}: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [currentPlan, setcurrentPlan]: [any, any] = useState(null);
  const [hasSubscription, setHasSubscription]: [any, any] = useState(HASSUBSCRIPTION);
  const [limitUnit, setLimitUnit]: [any, any] = useState(LIMITUNIT);

  const history = useHistory();

  useEffect(() => {
    // console.log('Learn.useEffect[]....');
    recordPageGA('/learn');

    if (user) {
      loadResponses(user.id);
      loadUserStrengthen(user.id);
      const age = user.age ? user.age : 1;
      const newPlan = JSON.parse(JSON.stringify(plan[age]));
      setcurrentPlan(newPlan);
    }

    // load products for ios
    // console.log('Learn: products::', products, 'transactions::', transactions);
    const os = getMobileOperatingSystem().toLocaleLowerCase();
    if (/* os=='ios' &&  */!products) loadSubscriptions();

  }, []);

  const _evalHasSubscription = () => {
    let hasSubscription = userHasSubscription(transactions, user);
    setHasSubscription(hasSubscription);
    setLimitUnit(hasSubscription ? 0 : LIMITUNIT)
  }

  useEffect(() => {
    // console.log('useEffect[transactions]...');
    if (transactions) {
      _evalHasSubscription();
    }
  }, [transactions]);

  useEffect(() => {
    // console.log('useEffect[user]...');
    if (user) {
      _evalHasSubscription();
    }
  }, [user]);


  useEffect(() => {
    if (results) {
      // console.log('Learn useEffect[results]:', results.current);
      moveToScrollToElem(results.current);
      // moveToScrollPoition();
    }
  }, [results]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (modalVisible) {
        setModalVisible(false);
        return false; // Prevent navigation
      }
    });

    return () => {
      unblock();
    };
  }, [modalVisible, setModalVisible, history]);

  const showModal = content => {
    // storeScrollPosition();

    setModalContent(content);
    setModalVisible(true);
  };

  useEffect(() => {
    if (!modalVisible) unlockScreen();
  }, [modalVisible]);

  const handleOk = e => {
    // console.log('Learn.handleOk', e);
    setModalVisible(false);
  };

  const handleCancel = e => {
    // console.log('Learn.handleCancel', e);
    setModalVisible(false);
    lockPortrait();
  };

  const handleAccChange = panel => { };

  const userId = user ? user.id : null;
  const current_level = results ? results['current_level'] : null;
  const currentLevel = (current_level && currentPlan) ? currentPlan.find(aL => aL.level === current_level) : null;
  const activeKey = (currentLevel && currentLevel.open) ? (results && results['current'] ? results['current'] : '') : '';

  const divStyle: any = {
    // height: `${innerHeight - 53}px`,
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: '53px'
  };

  // console.log('Learn: current_level:', current_level);

  if (!user) return <Redirect to="/profile/login" />;
  else
    return (
      <section className='MiSoroban'>
        <ModalLoader
          title={modalTitle}
          visible={modalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          content={modalContent}
          className="exercise-modal"
        />

        <div style={divStyle} className="learn">
          <div className='mis-wrapper'>
            <SectionHeader section={'learn'} />

            {isLoading ? <Spin size="large" className="spinner" /> : null}
            {!results || !currentPlan ? null : currentPlan.map((aP, idx) => {
              const current_level = results.current_level;
              // console.log('Learn.current_level:', idx, current_level, ':: hasSubscription:', hasSubscription, 'aP.level:', aP.level);
              let hasStrengthen = aP.units.find(aU => ('' + aU.uid).indexOf('se') >= 0);
              const activeLevel = hasSubscription ? (current_level >= aP.level) : (current_level >= aP.level && aP.level <= LIMITLEVEL);

              // console.log('Learn.level_strengthen_units:', strengthen_units, '>', hasStrengthen);
              if (strengthen_units && strengthen_units[aP.level]) {

                const level_strengthen_unit = {
                  uid: `se${aP.level}`,
                  label: 'strengthen',//'refuerzo',
                  icon: 'levels/strengthen.png',
                  exercises: strengthen_units[aP.level]
                };
                // console.log('Learn.level_strengthen_unit:', level_strengthen_unit);

                if (hasStrengthen) {
                  aP.units.pop();
                }
                aP.units.push(level_strengthen_unit);
              }

              return (
                <article className="level" data-level={idx + 1} key={idx}>
                  {aP.level == LIMITLEVEL && !hasSubscription && current_level >= LIMITLEVEL - 1 ? (
                    <SubscribeButton lang={lang} show={hasSubscription} />
                  ) : null}

                  <div
                    className={`level-separator ${activeLevel ? 'active' : ''}`}>
                    <span key="1"></span>
                    <span key="2">{aP.level}</span>
                    <span key="3"></span>
                  </div>

                  <Collapse
                    key={idx}
                    accordion
                    bordered={false}
                    defaultActiveKey={activeKey}
                    onChange={handleAccChange}>
                    {showLevelUnits(
                      aP,
                      showModal,
                      handleCancel,
                      results,
                      saveResponses,
                      lang,
                      limitUnit,
                      (idx < LIMITLEVEL - 1 ? true : hasSubscription)
                    )}
                    {showLevelTest(
                      aP,
                      showModal,
                      handleCancel,
                      results,
                      saveResponses,
                      lang,
                      (idx < LIMITLEVEL - 1 ? true : hasSubscription)
                    )}
                  </Collapse>

                </article>
              );
            })}
          </div>
        </div>

        <BottomMenu section={'learn'} />
      </section>
    );
};

const mapStateToProps = ({ user, response, subscription, tutor }) => ({
  user: user.user,
  results: response.data,
  isLoading: response.isFetching || tutor.isLoading,
  lang: user.lang,
  products: subscription.products,
  transactions: subscription.transactions,
  strengthen_units: tutor.strengthen_units
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadResponses,
      saveResponses,
      loadSubscriptions,
      getUserData,
      loadUserStrengthen
    },
    dispatch
  );

export default memo(connect(mapStateToProps, mapDispatchToProps)(Learn));
