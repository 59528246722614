const tutor1 = [
    {
        general_welcome: 'general_welcome',
        welcome: 'general_welcome_l1_welcome',
    },
    /**level 1: base, suma resta sencillas **/
    {
        t1_nok: 't1_feedback_nok',
        t1_nok_2: 't1_feedback_nok_2',
        t1_ok: 't1_feedback_ok_l2_welcome',
    },

    /**level 2: suma y resta 5 **/
    {
        t2_nok: 't2_feedback_nok',
        t2_nok_2: 't2_feedback_nok_2',
        t2_ok: 't2_feedback_ok_l3_welcome',
    },

    /**level 3: decenas y suma resta 10 **/
    {
        t3_nok: 't3_feedback_nok',
        t3_nok_2: 't3_feedback_nok_2',
        t3_ok: 't3_feedback_ok_l4_welcome',
    },

    /**level 4: centenas y suma resta 2c **/
    {
        t4_nok: 't4_feedback_nok',
        t4_nok_2: 't4_feedback_nok_2',
        t4_ok: 't4_feedback_ok_l5_welcome',
    },

    /**level 5: suma resta 3c **/
    {
        t5_nok: 't5_feedback_nok',
        t5_nok_2: 't5_feedback_nok_2',
        t5_ok: 'completed_training_l1',
    },
];


const tutor2 = [
    {
        general_welcome: 'general_welcome',
        welcome: 'general_welcome_l1_welcome',
    },
    /**level 1: base, suma resta sencillas **/
    {
        t1_nok: 't1_feedback_nok',
        t1_nok_2: 't1_feedback_nok_2',
        t1_ok: 't1_feedback_ok_l2_welcome',
    },

    /**level 2: suma y resta 5 **/
    {
        t2_nok: 't2_feedback_nok',
        t2_nok_2: 't2_feedback_nok_2',
        t2_ok: 't2_feedback_ok_l3_welcome',
    },

    /**level 3: decenas y suma resta 10 **/
    {
        t3_nok: 't3_feedback_nok',
        t3_nok_2: 't3_feedback_nok_2',
        t3_ok: 't3_feedback_ok_l4_welcome',
    },

    /**level 4: centenas y suma resta 2c **/
    {
        t4_nok: 't4_feedback_nok',
        t4_nok_2: 't4_feedback_nok_2',
        t4_ok: 't4_feedback_ok_l5_welcome',
    },

    /**level 5: suma resta 3c **/
    {
        t5_nok: 't5_feedback_nok',
        t5_nok_2: 't5_feedback_nok_2',
        t5_ok: 't5_feedback_ok_l6_welcome',
    },

    /**level 6: multiplicacionnes 1 c **/
    {
        t6_nok: 't6_feedback_nok',
        t6_nok_2: 't6_feedback_nok_2',
        t6_ok: 't6_feedback_ok_l7_welcome',
    },

    /**level 7: divisiones 1 c **/
    {
        t7_nok: 't7_feedback_nok',
        t7_nok_2: 't7_feedback_nok_2',
        t7_ok: 't7_feedback_ok_l8_welcome',
    },

    /**level 8: sumas 4c **/
    {
        t8_nok: 't8_feedback_nok',
        t8_nok_2: 't8_feedback_nok_2',
        t8_ok: 'completed_training_l2',
    },
];

const tutor3 = [
    {
        general_welcome: 'general_welcome',
        welcome: 'general_welcome_l1_welcome',
    },
    /**level 1: base, suma resta sencillas **/
    {
        t1_nok: 't1_feedback_nok',
        t1_nok_2: 't1_feedback_nok_2',
        t1_ok: 't1_feedback_ok_l2_welcome',
    },

    /**level 2: suma y resta 5 **/
    {
        t2_nok: 't2_feedback_nok',
        t2_nok_2: 't2_feedback_nok_2',
        t2_ok: 't2_feedback_ok_l3_welcome',
    },

    /**level 3: decenas y suma resta 10 **/
    {
        t3_nok: 't3_feedback_nok',
        t3_nok_2: 't3_feedback_nok_2',
        t3_ok: 't3_feedback_ok_l4_welcome',
    },

    /**level 4: centenas y suma resta 2c **/
    {
        t4_nok: 't4_feedback_nok',
        t4_nok_2: 't4_feedback_nok_2',
        t4_ok: 't4_feedback_ok_l5_welcome',
    },

    /**level 5: suma resta 3c **/
    {
        t5_nok: 't5_feedback_nok',
        t5_nok_2: 't5_feedback_nok_2',
        t5_ok: 't5_feedback_ok_l6_welcome',
    },

    /**level 6: multiplicacionnes 1 c **/
    {
        t6_nok: 't6_feedback_nok',
        t6_nok_2: 't6_feedback_nok_2',
        t6_ok: 't6_feedback_ok_l7_welcome',
    },

    /**level 7: divisiones 1 c **/
    {
        t7_nok: 't7_feedback_nok',
        t7_nok_2: 't7_feedback_nok_2',
        t7_ok: 't7_feedback_ok_l8_welcome',
    },

    /**level 8: sumas 4c **/
    {
        t8_nok: 't8_feedback_nok',
        t8_nok_2: 't8_feedback_nok_2',
        t8_ok: 't8_feedback_ok_l9_welcome',
    },

    /**level 9: mult 2c*/
    {
        t9_nok: 't9_feedback_nok',
        t9_nok_2: 't9_feedback_nok_2',
        t9_ok: 't9_feedback_ok_l10_welcome',
    },

    /**level 10: div 2c*/
{
        t10_nok: 't10_feedback_nok',
        t10_nok_2: 't10_feedback_nok_2',
        t10_ok: 'completed_training_l3',
    },
];


const tutor = {
    1: tutor1,
    2: tutor2,
    3: tutor3,
}

export default tutor;