import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { t } from "../../../languages";

import dbMgr from "../../../modules/dbManager";
import '../index.less';
import { BASE_VIDEO_URL } from "../../../modules/user";

let timer;
const WAITTIME = 300;
const WAITNEXTVIDEO = 100;

const GENERAL_WELCOME = 'general_welcome';

const AskIfLikeKoyu = ({ lang, handleYes, handleNo }) => {
    return <>
        <h3>{t(lang, 'doyoulikekoyu')}</h3>
        <div className='botonera'>
            <Button onClick={handleYes}>{t(lang, 'yes')}</Button> <Button onClick={handleNo}>{t(lang, 'no')}</Button>
        </div>
    </>;
}

const AskIfWannaTryTutor = ({ lang, handleYes, handleNo }) => {
    return <>
        <h3 className="wannatrytutor">
            <span>{t(lang, 'wannatrytutor')}</span>
            <img src="./static/koyu/general_welcome.jpg" />
        </h3>
        <div className='botonera'>
            <Button onClick={handleYes}>{t(lang, 'yes')}</Button> <Button onClick={handleNo}>{t(lang, 'no')}</Button>
        </div>
    </>;
}

const Tutor = ({ lang, age, themes, handleCloseManager, user, shown_tutor, reviewTutor, reviewed_tutor, updateUserFields }) => {
    const [open, setOpen] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [ended, setEnded] = useState(false);
    const [blob, setBlob] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [currentThemeIdx, setCurrentThemeIdx] = useState(0);
    const [givenFeedback, setGivenFeedback] = useState(reviewed_tutor || false);
    const [isGeneralWelcome, setIsGeneralWelcome] = useState(false);
    const [wannaTryTutor, setWannaTryTutor] = useState(false);
    const [showControls, setShowControls] = useState(false);

    useEffect(() => {
        // console.log('Tutor.pRef:', pRef.current);
        const vkey = `${themes[currentThemeIdx]}_${lang}`;

        _readVideoFromDB(vkey);

        timer = setTimeout(() => {
            setOpen(true);
        }, WAITTIME);

        const currentTheme = themes[currentThemeIdx];
        const _isGeneralWelcome = currentTheme && currentTheme == GENERAL_WELCOME;
        setIsGeneralWelcome(_isGeneralWelcome);
        if (!_isGeneralWelcome) setWannaTryTutor(true);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    const _readVideoFromDB = (vkey) => {
        dbMgr.readFromDb(vkey).then((blob) => {
            const videoBlob = URL.createObjectURL(blob);
            setBlob(videoBlob);
            setPlaying(true);

        }).catch((err) => {
            // console.log('Tutor._readVideoFromDB... Loading from url...');
            const url = `${BASE_VIDEO_URL}/${vkey}.mp4`;
            setVideoUrl(url);
        });
    }

    const handleCancel = () => {
        // setOpen(false);
    };

    const handleAccept = () => {
        if (currentThemeIdx < themes.length - 1) {
            const vkey = `${themes[currentThemeIdx + 1]}_${lang}`;
            _readVideoFromDB(vkey);
            setCurrentThemeIdx(currentThemeIdx + 1);
            // setPlaying(true);
            setEnded(false);
        }
        else setOpen(false);
    };

    const handleStrengthen = () => {
        console.log('Tutor.handleStrengthen....');
        setOpen(false);
    };


    const handleReset = () => {
        handleCloseManager('tutor', themes);
    };

    const handleReady = () => {
        // const video = pRef.current.getInternalPlayer();
    }

    const handleProgress = (progress) => {
        // console.log('Tutor.progress:', progress);
    }

    const handleEnd = () => {
        // console.log('Tutor.handleEnd...');
        setEnded(true);
    }

    const handleError = () => {
        // console.log('Tutor.handleError...');
        setEnded(true);
    }

    const handleYesKoyu = () => {
        const review = {
            liked: 'yes',
            user: user.id,
            type: 'koyu'
        }
        setGivenFeedback(true);
        reviewTutor(review);
    };

    const handleNoKoyu = () => {
        const review = {
            liked: 'no',
            user: user.id,
            type: 'koyu'
        }
        setGivenFeedback(true);
        reviewTutor(review);
    };

    const handleYesTutor = () => {
        setWannaTryTutor(true);
    };

    const handleNoTutor = () => {
        updateUserFields({ tutor: false })
        setOpen(false);
    };

    const handleClick = () => {
        console.log('clicked!!!');
        
        setShowControls(!showControls);
    }


    const currentTheme = themes[currentThemeIdx];
    // console.log('Tutor.currentTheme:', currentTheme);
    const showPractice = currentTheme.indexOf('nok') >= 0 || false;
    const testAlreadyShown = shown_tutor ? shown_tutor.substring(1, 2) : 0;
    const isAnyWelcome = currentTheme.indexOf('welcome') >= 0 || false;
    const showFeedBack = ended && !isGeneralWelcome && testAlreadyShown >= 2 && !givenFeedback;
    // console.log('Tutor.showFeedBack:', showFeedBack,'::', testAlreadyShown, givenFeedback);

    return <>

        <Modal
            open={open}
            title={null}
            onCancel={handleCancel}
            footer={null}
            className='tutor-modal confirm'
            afterClose={handleReset}
            closable={false}
            destroyOnClose={true}
        >
            <div className="body">
                {isGeneralWelcome && !wannaTryTutor ? <AskIfWannaTryTutor lang={lang} handleYes={handleYesTutor} handleNo={handleNoTutor} /> : null}
                {wannaTryTutor && open && (blob || videoUrl) ?
                    <video
                        src={blob || videoUrl}
                        muted={false} //false in PROD
                        autoPlay={/* false  */playing} //playing in PROD
                        controls={/* true */ showControls && isAnyWelcome} //false in PROD
                        onEnded={handleEnd}
                        onError={handleError}
                        onClick={handleClick}
                        style={{ width: 'auto', height: '50vh' }}
                        poster={`./static/koyu/${themes[currentThemeIdx]}.jpg`}
                    /> : null}
                {/* <h3>Koyu: {themes[currentThemeIdx]}-{shown_tutor}</h3> */}
                {showFeedBack ? <AskIfLikeKoyu lang={lang} handleYes={handleYesKoyu} handleNo={handleNoKoyu} /> : null}
            </div>
            {wannaTryTutor ? <div className='botonera'>
                {showPractice ? <Button onClick={handleStrengthen} disabled={!ended || showFeedBack} >{t(lang, 'strengthen')}</Button> : <Button key={currentTheme} onClick={handleAccept} disabled={!ended || showFeedBack} >{t(lang, 'ok')}</Button>}
            </div> : null}
        </Modal>
    </>;
}

export default Tutor;
