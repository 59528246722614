import { Switch } from 'antd';

const ActivateTutor = ({ isActive, canActive, handleChange }) => {

    const onChange = (val)=>{
        handleChange(val);
    }

    return <Switch checked={isActive} disabled={!canActive} onChange={onChange} />;
}

export default ActivateTutor;
