import { Button, Modal, Radio, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { t } from '../../languages';
import { addDailyGoalReached, PRODUCTS, updateUserFields } from '../../modules/user';
import './index.less';
import GoalOptions from './GoalOptions';
import { Flat, Heat } from '@alptugidin/react-circular-progress-bar';
import { formatMS2MMSS } from '../../utils';

let timer;
const WAITTIME = 1000;
const WAITTIME2 = 300;
const DEFAULT_DAILY_GOAL = 10;

const SetDailyGoal = ({ lang, user, dailyGoal, handleGoalChange, handleDone }) => {
    return <>
        <h2>{t(lang, 'dailygoal')}</h2>
        <p>
            {t(lang, 'hi')} {user.name} !
            <br />
            {t(lang, 'selectdailygoal')}
        </p>
        <p>
            <img className='logo' src="./static/daily/meta_diaria.svg" alt="" />
        </p>
        <GoalOptions dailyGoal={dailyGoal} handleGoalChange={handleGoalChange} />
        <p>
            <br />
            <Button onClick={handleDone}>{t(lang, 'done')}</Button>
        </p>
    </>;
}

const CongratsDailyGoal = ({ lang, user, handleAccept }) => {
    const isCourse = user && user.products && user.products.includes(PRODUCTS.COURSE) || false;


    return <>
        <h2>{t(lang, 'dailygoalreached')}</h2>

        <p>
            <img className='logo' src="./static/daily/daily_goal.png" alt="" />
        </p>
        <p>
            {user.name}, {t(lang, 'youreachurdailygoal')} {user.dailyGoal} mins.
        </p>
        {isCourse ? null : <h3>
            {t(lang, 'youwon')} <span className='points'><img src="./static/myspace/coin.svg" alt="" />10</span>
        </h3>}
        <p>
            <Button onClick={handleAccept}>{t(lang, 'accept')}</Button>
        </p>
    </>;
}

const PercentageDailyGoal = ({ lang, user, today_time, handleAccept, percent }) => {
    const cheers = {
        25: 'keepon',
        50: 'halfway',
        75: 'almosthere',
    };

    return <>
        <h2>{t(lang, 'dailygoal')}</h2>

        <div className='circular-progress'>
            <Flat
                progress={percent}
                range={{ from: 0, to: 100 }}
                sign={{ value: '%', position: 'end' }}
                showValue={true}
                revertBackground={false}
                text={t(lang, 'match')}
                sx={{
                    shape: 'threequarters',
                    barWidth: 8,
                    valueSize: 18,
                    textSize: 18,
                    valueFamily: 'Nunito',
                    textFamily: 'Nunito',
                    valueWeight: 'bold',
                    textWeight: 'normal',
                    textColor: '#4e4d4d',
                    valueColor: '#4e4d4d',
                    loadingTime: 1000,
                    strokeColor: '#FBC511',
                    bgStrokeColor: '#fcfcfc',
                    strokeLinecap: 'round',
                    valueAnimation: true,
                    intersectionEnabled: true
                }}
            />

        </div>
        <p>
            {user.name}, {t(lang, 'youhavebeenworking')} {formatMS2MMSS(today_time)} {t(lang, 'outof')} {user.dailyGoal} min.
        </p>
        <h3>
            {t(lang, cheers[percent])}
        </h3>
        <p>
            <Button onClick={handleAccept}>{t(lang, 'accept')}</Button>
        </p>
    </>;
}

const DailyGoal = ({ lang, user, updateUserFields, isLoading, today_time, addDailyGoalReached, daily_goal_reached, handleCloseManager }) => {
    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        const uses = user.uses || 0;
        const dailyGoalMS = user.dailyGoal ? user.dailyGoal * 60 * 1000 : 0;

        // console.log('DailyGoal dailyGoal useEffect[user]:', dailyGoalMS, user.dailyGoal, today_time, daily_goal_reached);

        if (!user.dailyGoal && (uses === 0 || uses % 5 !== 0)) {
            timer = setTimeout(() => {
                showModal();
            }, WAITTIME);
        } else {
            evalDailyGoalReached(dailyGoalMS);
        }
    }, [user]);

    useEffect(() => {

        const dailyGoalMS = user.dailyGoal ? user.dailyGoal * 60 * 1000 : 0;
        // console.log('DailyGoal dailyGoal useEffect[today_time]:', dailyGoalMS, user.dailyGoal, today_time, daily_goal_reached);

        evalDailyGoalReached(dailyGoalMS);
    }, [today_time]);


    const [open, setOpen] = useState(false);
    const [dailyGoal, setDailyGoal] = useState(user && user.dailyGoal ? user.dailyGoal : DEFAULT_DAILY_GOAL);
    const [dailyGoalReached, setDailyGoalReached] = useState(false);
    const [showPercent, setShowPercent] = useState(false);
    const [percent, setPercent] = useState(0);

    const evalDailyGoalReached = (dailyGoalMS) => {
        const todayTimePercent = (today_time >= dailyGoalMS && dailyGoalMS > 0) ? 100 : (dailyGoalMS ? Math.round((today_time / dailyGoalMS) * 100) : 0);
        const todayTimePercentRamge = _calcRange(todayTimePercent);
        const dailyGoalReachedPercentRamge = daily_goal_reached ? _calcRange(daily_goal_reached) : 0;

        // console.log('DailyGoal evalDailyGoalReached todayTimePercent:', dailyGoalMS, today_time, '::', todayTimePercent, daily_goal_reached, '::', todayTimePercentRamge, dailyGoalReachedPercentRamge);

        if (todayTimePercent == 100 && daily_goal_reached < 100) {
            // addDailyGoalReached(todayTimePercent);
            setPercent(100);
            setDailyGoalReached(true);
            clearTimeout(timer);
            timer = setTimeout(() => {
                showModal();
            }, WAITTIME2);
        } else {
            let canShowPercentModal = false;
            // if (todayTimePercentRamge == 0) canShowPercentModal = true; // For testing!!!
            if (user.dailyGoal <= 5 && todayTimePercentRamge == 2) canShowPercentModal = true;
            if (user.dailyGoal == 10 && todayTimePercentRamge == 2) canShowPercentModal = true;
            if (user.dailyGoal == 15) canShowPercentModal = true;

            // console.log('DailyGoal evalDailyGoalReached canShowPercentModal:', canShowPercentModal, todayTimePercentRamge, dailyGoalReachedPercentRamge);

            if (canShowPercentModal && todayTimePercentRamge > dailyGoalReachedPercentRamge) {
                setPercent(todayTimePercent);
                setShowPercent(true);
                clearTimeout(timer);
                timer = setTimeout(() => {
                    showModal();
                }, WAITTIME2);
            }
            // if (todayTimePercent > 0) addDailyGoalReached(todayTimePercent);
        }
    }

    const _calcRange = (percent) => {
        if (percent < 25) return 0;
        else if (percent < 50) return 1;
        else if (percent < 75) return 2;
        else if (percent < 100) return 3;
        else return 0;
    }

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        // setOpen(false);
    };

    const handleAccept = () => {
        setOpen(false);
    };

    const handleReset = () => {
        if (percent > 0) addDailyGoalReached(percent);
        handleCloseManager('dailyGoal');
    };

    const handleGoalChange = (e) => {
        setDailyGoal(e.target.value);
    };

    const handleDone = () => {
        // console.log('DailyGoal handleDone:', dailyGoal);
        updateUserFields({ dailyGoal: dailyGoal });
        setOpen(false);

    };

    // console.log('DailyGoal render....');

    return <>
        {/* {isLoading ? <Spin size="large" className="spinner" /> : null} */}

        <Modal
            open={open}
            title={null}
            onCancel={handleCancel}
            footer={null}
            className='dailygoal-modal confirm'
            afterClose={handleReset}
            closable={false}
            destroyOnClose={true}
        >
            {!(dailyGoalReached || showPercent) ? <SetDailyGoal lang={lang} user={user} dailyGoal={dailyGoal} handleGoalChange={handleGoalChange} handleDone={handleDone} /> : null}
            {dailyGoalReached ? <CongratsDailyGoal lang={lang} user={user} handleAccept={handleAccept} /> : null}
            {showPercent && !dailyGoalReached ? <PercentageDailyGoal lang={lang} user={user} handleAccept={handleAccept} today_time={today_time} percent={percent} /> : null}
        </Modal>
    </>;
}

const mapStateToProps = ({ user }) => ({
    user: user.user,
    lang: user.lang,
    isLoading: user.isLoading,
    today_time: user.today_time,
    daily_goal_reached: user.daily_goal_reached,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateUserFields,
            addDailyGoalReached
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DailyGoal);
