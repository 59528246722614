import { Radio } from "antd";

const GoalOptions = ({ dailyGoal, handleGoalChange }) => {
    return <Radio.Group onChange={handleGoalChange} value={dailyGoal}>
        <Radio value={5} key={5}>5 min.</Radio>
        <Radio value={10} key={10}>10 min.</Radio>
        <Radio value={15} key={15}>15 min.</Radio>
    </Radio.Group >;
}

export default GoalOptions;
